<template>
  <div id="services">
    <div class="ServicesStyle">
      <div class="blur"><div></div></div>
      <TitlePage
        :showButton="false"
        class="home-title"
        :header="header"
        :description="description"
      />
    </div>
    <DetailsTemplate
      v-for="service in services"
      :key="service.id"
      :id="service.id"
      :CheckupHeader="service.CheckupHeader"
      :CheckupDescription="service.CheckupDescription"
      :image="service.checkupImage"
    />

    <router-link to="/contact"
      ><button class="about-btn">Make An Appointment</button></router-link
    >
    <Footer />
  </div>
</template>

<script>
import TitlePage from "../components/layouts/TitlePage";
import DetailsTemplate from "../components/layouts/DetailsTemplate";
import Footer from "../components/Footer";

export default {
  name: "Services",
  components: {
    TitlePage,
    DetailsTemplate,
    Footer,
  },
  data() {
    return {
      header: "Our Services",
      description:
        "The services we offer are targeted at helping the community. We believe with high-quality services that we offer we can be a part of creating a healthy community today and tomorrow.",

      services: [
        {
          id: 0,
          CheckupHeader: "Advanced Obstetrics Ultrasound",
          CheckupDescription:
            "Pregnant women are recommended to have these 5 Obstetric scans. Early OB scan that is done before 8 weeks, 1st trimester NT SCAN at 12 weeks, Anomaly scan at 19-22 weeks, growth scan at 28-32 weeks, growth scan 37 weeks, High-risk pregnancy may need more scans such as in multiple pregnancies, twins, triplets, or quadruplets.",
          checkupImage: require("@/assets/servicesImages/0499e6_60f77b108b054e3a860a07656d661cbc_mv2.webp"),
        },
        {
          id: 1,
          CheckupHeader: "Echocardiography",
          CheckupDescription:
            "An echocardiogram (echo) is a graphic outline of the heart's movement. During an echo test, ultrasound (high-frequency sound waves) from a hand-held wand placed on your chest provides pictures of the heart's valves and chambers and helps the sonographer evaluate the pumping action of the heart. We offer different kinds of Echocardiography services including Fetoechocardiography.",
          checkupImage: require("@/assets/servicesImages/0499e6_92ab1e7fe7cb45b285758e218ba11ae0_mv2.webp"),
        },
        {
          id: 2,
          CheckupHeader: "Infertility Services",
          CheckupDescription:
            "Many causes of male and female infertility can be successfully overcome using a range of treatment options. We offer infertility services including Testicular Sperm Extraction and Intra Uterine Insemination.",
          checkupImage: require("@/assets/servicesImages/0499e6_716fd96039c345c797a720bd03a20947_mv2.webp"),
        },
        {
          id: 3,
          CheckupHeader: "Holter Monitoring Services",
          CheckupDescription:
            "A Holter monitor is a battery-operated portable device that measures and records your heart's activity (ECG) continuously for 24 to 48 hours or longer depending on the type of monitoring used.  Wearing the monitor may determine if your medicines are working, why you have symptoms such as dizziness, faintness or the feeling that your heart is racing or skipping a beat, or if your heart is getting enough oxygen to meet its needs.",
          checkupImage: require("@/assets/servicesImages/0499e6_e58e4be1c2144faf9c8c1ae959721238_mv2.webp"),
        },
        {
          id: 4,
          CheckupHeader: "Pharmaceutical Services",
          CheckupDescription:
            "We sell a wide range of pharmaceutical products including medicine, cosmetics, and supplies for the hospital. We are open 24 Hours to make it convenient for everyone to visit at any time of need.",
          checkupImage: require("@/assets/servicesImages/0499e6_d4e8f1686c6e43939a674bc54a4651d4_mv2.webp"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.ServicesStyle{
  contain:content
}
.about-btn {
  height: 40px;
  background-color: #ce515c;
  border: unset;
  color: #fff;
}
button {
  margin-top: 2%;
  transition: 200ms;
  transition-timing-function: ease-in-out;
}
button:hover {
  opacity: 0.9;
}
/* .ServicesStyle {
  background-image: url("../assets/images/royal hospitals dr glory shoot-79.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
} */
/* .home-title {
  background-color: rgba(194, 31, 31, 0.4);
  height: 70vh;

  display: grid;
  place-items: center;
} */
.blur {
  z-index: -2;
  position: absolute;
  background-image: url("../assets/images/royal hospitals dr glory shoot-79.webp");
  background-position: center;
  background-size: cover;
  filter: blur(0px);
  -webkit-filter: blur(4px);
  width: 100%;
  height: 100%;
}
.blur div {
  background-color: rgba(59, 77, 177, 0.5);
  /* width: 110%;
  height: 100%; */
}
.home-title {
   position: relative;
  height: 70vh;
  background-color: rgba(177, 59, 59, 0.5);
  display: grid;
  place-items: center;
}
.CheckupSections {
  display: flex;
  background-color: salmon;
}
.colums {
  width: 100%;
  background-color: seagreen;
}
</style>